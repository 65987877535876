@tailwind base;
@tailwind components;
@tailwind utilities;

/*html2canvas*/
@layer base {
    img {
        @apply inline-block;
    }
}

@font-face {
    font-family: 'Code Mono';
    src: url('../public/assets/fonts/CodoMono-Regular.woff2') format('woff2'),
        url('../public/assets/fonts/CodoMono-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Code Mono';
    src: url('../public/assets/fonts/CodoMono-Bold.woff2') format('woff2'),
        url('../public/assets/fonts/CodoMono-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Code Mono';
    src: url('../public/assets/fonts/CodoMono-Medium.woff2') format('woff2'),
        url('../public/assets/fonts/CodoMono-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Space Mono';
    src: url('../public/assets/fonts/SpaceMono-Regular.woff2') format('woff2'),
        url('../public/assets/fonts/SpaceMono-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Apercu Mono Pro';
    src: url('../public/assets/fonts/ApercuMonoPro-Medium.woff2')
            format('woff2'),
        url('../public/assets/fonts/ApercuMonoPro-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Apercu Mono Pro';
    src: url('../public/assets/fonts/ApercuMonoPro-Light.woff2') format('woff2'),
        url('../public/assets/fonts/ApercuMonoPro-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Apercu Mono Pro';
    src: url('../public/assets/fonts/ApercuMonoPro-Regular.woff2')
            format('woff2'),
        url('../public/assets/fonts/ApercuMonoPro-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Apercu Mono Pro';
    src: url('../public/assets/fonts/ApercuMonoPro-Bold.woff2') format('woff2'),
        url('../public/assets/fonts/ApercuMonoPro-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

/*New fonts*/
@font-face {
    font-family: 'RFDewi';
    src: url('../public/assets/fonts/headline_font_RFDewiExpanded-Semibold.ttf')
        format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Sculpin';
    src: url('../public/assets/fonts/fonnts.com-Sculpin_Black_Italic.otf')
        format('opentype');
    font-weight: 900;
    font-style: italic;
}
@font-face {
    font-family: 'Sculpin';
    src: url('../public/assets/fonts/fonnts.com-Sculpin_Black.otf')
        format('opentype');
    font-weight: 900;
    font-style: normal;
}
@font-face {
    font-family: 'Sculpin';
    src: url('../public/assets/fonts/fonnts.com-Sculpin_Bold_Italic.otf')
        format('opentype');
    font-weight: bold;
    font-style: italic;
}
@font-face {
    font-family: 'Sculpin';
    src: url('../public/assets/fonts/fonnts.com-Sculpin_Bold.otf')
        format('opentype');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Sculpin';
    src: url('../public/assets/fonts/fonnts.com-Sculpin_Light_Italic.otf')
        format('opentype');
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: 'Sculpin';
    src: url('../public/assets/fonts/fonnts.com-Sculpin_Light.otf')
        format('opentype');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Sculpin';
    src: url('../public/assets/fonts/fonnts.com-Sculpin_Medium_Italic.otf')
        format('opentype');
    font-weight: 500;
    font-style: italic;
}
@font-face {
    font-family: 'Sculpin';
    src: url('../public/assets/fonts/fonnts.com-Sculpin_Medium.otf')
        format('opentype');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Sculpin';
    src: url('../public/assets/fonts/fonnts.com-Sculpin_Regular_Italic.otf')
        format('opentype');
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: 'Sculpin';
    src: url('../public/assets/fonts/fonnts.com-Sculpin_Regular.otf')
        format('opentype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Sculpin';
    src: url('../public/assets/fonts/fonnts.com-Sculpin_Variable_Light_Italic.otf')
        format('opentype');
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: 'Sculpin';
    src: url('../public/assets/fonts/fonnts.com-Sculpin_Variable_Light.otf')
        format('opentype');
    font-weight: 300;
    font-style: normal;
}

::-moz-selection {
    background: #00ffc2;
    color: #1b1b1b;
}
::selection {
    background: #00ffc2;
    color: #1b1b1b;
}

.modal-body::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

.modal-body::-webkit-scrollbar-track {
    background-color: #f3f2ee;
}

.modal-body::-webkit-scrollbar-thumb {
    background-color: #b2b1ad;
}

.bar-container > span {
    opacity: 0;
    top: 15px;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 1000ms;
}

.bar-container:hover > span {
    opacity: 1;
    top: 10px;
}

table {
    display: flex;
    flex-flow: column;
    height: 100%;
    width: 100%;
}
table thead {
    /* head takes the height it requires,
    and it's not scaled when table is resized */
    flex: 0 0 auto;
    width: calc(100% - 0.9em);
}
table tbody {
    /* body takes all the remaining available space */
    flex: 1 1 auto;
    display: block;
    overflow-y: scroll;
}
table tbody tr {
    width: 100%;
}
table thead,
table tbody tr {
    display: table;
    table-layout: fixed;
}

body,
html {
    scroll-behavior: smooth;
}

html,
head,
body,
#root {
    height: 100%;
}

body {
    margin: 0;
    padding: 0;
    background-color: #303030;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-overflow-scrolling: touch;
    text-rendering: optimizeLegibility;
    min-height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'RFDewi';
}

p,
div {
    font-family: 'Sculpin';
}

*::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    border-radius: 10px;
}

*::-webkit-scrollbar-track {
    background: #1a1a1aaa;
    border-radius: 10px;
}

*::-webkit-scrollbar-thumb {
    background-color: #12121277;
    border-radius: 10px;
}

.apexcharts-tooltip {
    border-radius: 0 !important;
    color: #fff;
    transform: translateX(-10px) translateY(-16px);
    overflow: visible !important;
    white-space: normal !important;
}

.apexcharts-tooltip span {
    padding: 2px 4px 0;
}

.apexcharts-tooltip.apexcharts-theme-light {
    border: none !important;
}

.label-wallet:hover > svg g {
    fill: #00ffc2;
}

#info-tooltip:hover > #Background {
    fill: #dddad4;
}

#info-tooltip:hover > #Icon {
    fill: #1b1b1b;
}

@layer components {
    /*.form-item {*/
    /*    @apply font-medium appearance-none block w-full py-3 text-neutral-100 px-0 border-x-0 border-t-0 border-b-2 border-b-neutral-100 rounded-sm placeholder-neutral-40 indent-0 focus:outline-none focus:ring-0 focus:border-b-primary bg-transparent sm:text-lg*/
    /*}*/

    .text-title {
        @apply font-sans uppercase text-xs tracking-wide;
    }

    .text-label {
        @apply font-sans font-normal;
    }

    .card {
        background-color: theme('colors.white');
        border-radius: theme('borderRadius.lg');
        padding: theme('spacing.6');
        box-shadow: theme('boxShadow.xl');
    }

    .loop {
        animation-name: loadA;
        animation-duration: 1s;
        animation-delay: 2s;
        animation-iteration-count: infinite;
        animation-direction: alternate;
    }

    .loop-b {
        animation-name: loadB;
    }
    .loop-c {
        animation-name: loadC;
    }
    .loop-d {
        animation-name: loadD;
    }

    .disable-scrollbars::-webkit-scrollbar {
        background: transparent; /* Chrome/Safari/Webkit */
        width: 0;
        display: none;
    }

    .icon-select {
        -webkit-appearance: none;
        -moz-appearance: none;
        background: transparent;
        background-image: url('./assets/svg/chevron.svg');
        background-repeat: no-repeat;
        background-position-x: 95%;
        background-position-y: 8px;
    }

    .retry:hover > svg > g > #db_empty {
        fill: #00ffc2;
    }

    input[type='range'] {
        @apply appearance-none bg-transparent;
    }

    .trade-report > div > svg > path {
        fill: #b2b1ad;
    }

    .trade-report:hover > div > h2 {
        color: #00ffc2;
    }

    .trade-report:hover > div > svg > path {
        fill: #00ffc2;
    }

    .trade-report > div > div > svg > path {
        fill: #b2b1ad;
    }

    .trade-report:hover > div > div > div > h2 {
        color: #00ffc2;
    }

    .trade-report:hover > div > div > svg > path {
        fill: #00ffc2;
    }

    .arrow > path {
        fill: #b2b1ad;
    }

    .arrow-dark > path {
        fill: #303030;
    }

    .arrow-hover > path {
        fill: #00ffc2;
    }

    input[type='range']::-webkit-slider-thumb {
        @apply h-4 w-4 bg-primary rounded-full cursor-pointer appearance-none -mt-[6px] z-10 relative;
    }

    input[type='range']::-moz-range-thumb {
        @apply h-4 w-4 bg-primary rounded-full cursor-pointer appearance-none -mt-[6px] z-10 relative;
    }

    input[type='range']::-ms-thumb {
        @apply h-4 w-4 bg-primary rounded-full cursor-pointer appearance-none -mt-[6px] z-10 relative;
    }

    input[type='range']::-webkit-slider-runnable-track {
        @apply bg-grey-300 rounded-full h-1;
    }

    input[type='range']::-moz-range-track {
        @apply bg-primary rounded-full;
    }

    input[type='range']::-ms-track {
        @apply bg-primary rounded-full;
    }

    input[type='checkbox']:checked {
        background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%2300FFC2' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
    }

    input[type='number'] {
    }

    .scroller::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }

    .scroller::-webkit-scrollbar-track {
        background: #1b1b1b;
    }

    .scroller::-webkit-scrollbar-thumb {
        background-color: #2d2d2d;
    }

    .dark-button {
        background-color: #303030 !important;
        color: #f3f2ee !important;
    }

    .dark-button:hover {
        background-color: #f3f2ee !important;
        color: #303030 !important;
    }

    .force-button {
        background-color: #303030 !important;
    }

    .force-button:hover {
        color: #484848 !important;
    }
}

@keyframes loadA {
    0% {
        background: #323232;
    }
    34% {
        background: #494949;
    }
    67% {
        background: #606060;
    }
    100% {
        background: #767676;
    }
}

@keyframes loadB {
    0% {
        background: #494949;
    }
    34% {
        background: #323232;
    }
    67% {
        background: #767676;
    }
    100% {
        background: #606060;
    }
}

@keyframes loadC {
    0% {
        background: #606060;
    }
    34% {
        background: #494949;
    }
    67% {
        background: #323232;
    }
    100% {
        background: #767676;
    }
}

@keyframes loadD {
    0% {
        background: #767676;
    }
    34% {
        background: #606060;
    }
    67% {
        background: #494949;
    }
    100% {
        background: #323232;
    }
}

.flickity-enabled {
    position: relative;
}

.flickity-enabled:focus {
    outline: none;
}

.flickity-viewport {
    overflow: hidden;
    position: relative;
    height: 100%;
}

.flickity-slider {
    position: absolute;
    width: 100%;
    height: 100%;
}

/* draggable */

.flickity-enabled.is-draggable {
    -webkit-tap-highlight-color: transparent;
    tap-highlight-color: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
    cursor: move;
    cursor: -webkit-grab;
    cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
    cursor: -webkit-grabbing;
    cursor: grabbing;
}

.carousel-active {
    flex: 1;
}

/* ---- flickity-button ---- */

.flickity-button {
    position: absolute;
    background: hsla(0, 0%, 100%, 0.75);
    border: none;
    color: #333;
}

.flickity-button:hover {
    background: white;
    cursor: pointer;
}

.flickity-button:focus {
    outline: none;
    box-shadow: 0 0 0 5px #19f;
}

.flickity-button:active {
    opacity: 0.6;
}

.flickity-button:disabled {
    opacity: 0.3;
    cursor: auto;
    /* prevent disabled button from capturing pointer up event. #716 */
    pointer-events: none;
}

.flickity-button-icon {
    fill: #333;
}

/* ---- previous/next buttons ---- */

.flickity-prev-next-button {
    bottom: -60px;
    width: 34px;
    height: 34px;
    border-radius: 50%;
    /* vertically center */
    z-index: 100;
}

.carousel-prev > .flickity-prev-next-button {
    bottom: -50px;
}

.flickity-prev-next-button.previous {
    right: 40px;
}
.flickity-prev-next-button.next {
    right: 0px;
}
/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
    left: auto;
    right: 10px;
}
.flickity-rtl .flickity-prev-next-button.next {
    right: auto;
    left: 10px;
}

.flickity-button {
    position: absolute;
    background: transparent;
    border: none;
    color: #333;
}

.flickity-button:focus {
    outline: none;
    box-shadow: none;
}

.flickity-prev-next-button .flickity-button-icon {
    position: absolute;
    left: 20%;
    top: 20%;
    width: 60%;
    height: 60%;
}

/* ---- page dots ---- */

.flickity-page-dots {
    position: absolute;
    width: 100%;
    bottom: -45px;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
    line-height: 1;
}

.flickity-rtl .flickity-page-dots {
    direction: rtl;
}

.flickity-page-dots .dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 8px;
    background: #333;
    border-radius: 50%;
    opacity: 0.25;
    cursor: pointer;
}

.flickity-page-dots .dot.is-selected {
    opacity: 1;
}

.axistitle {
    position: absolute;
    font-family: 'Apercu Mono Pro';
    font-size: 10px;
    font-weight: 300;
    line-height: 13px;
    text-align: right;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    color: #b2b1ad;
}

.apexcharts-point-annotation-marker {
    stroke-opacity: 0.3;
}
/*----------*/
.radial {
    height: 150px;
    width: 150px;
}
#progress,
#progress-border,
#track,
#border-track {
    fill: transparent;
}
#progress {
    stroke: #00ffc2;
    stroke-width: 8px;
    stroke-linecap: round;
    stroke-dasharray: 409;
    stroke-dashoffset: 140; /* Change number value to shift progress bar */
}
#progress-border {
    stroke: #00ffc2;
    stroke-width: 8px;
    stroke-linecap: round;
    stroke-dasharray: 409;
    stroke-dashoffset: 140; /* Change number value to shift progress bar */
}
#track {
    stroke: #1b1b1b;
    stroke-width: 6px;
}
#border-track {
    stroke: #1b1b1b;
    stroke-width: 6px;
}

.radial-background {
    background: rgb(48, 48, 48);
    background: radial-gradient(
        circle,
        rgba(48, 48, 48, 1) 16%,
        rgba(27, 27, 27, 1) 52%
    );
}

.apexcharts-tooltip-candlestick {
    color: #1b1b1b;
    padding: 1rem;
}

.apexcharts-tooltip-candlestick .value {
    font-weight: 600;
    font-family: 'Code Mono';
}

/*swiper*/
.swiper-pagination {
    margin-bottom: -5px;
}
.swiper {
    padding-bottom: 45px !important;
}
.swiper-pagination-bullet {
    background: #d9d9d9 !important;
}
.swiper-pagination-bullet-active {
    background: #00ffc2 !important;
}

.fade-in {
    opacity: 1;
}

.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker__input-container input {
    background: #2b2b2b;
    border-radius: 6px;
    color: white;
}

/*swal dark*/
.swal2-popup {
    position: relative; /* Needed for proper stacking */
    background: rgba(150, 150, 150, 0.2); /* Semi-transparent background */
    border-radius: 10px; /* Rounded corners if desired */
    backdrop-filter: blur(20px); /* Standard backdrop blur */
    -webkit-backdrop-filter: blur(20px); /* Safari support */
    border: 1px solid rgba(200, 200, 200, 0.3);
}
.swal2-title {
    color: white;
    font-family: Code Mono;
    font-weight: 400;
}
.swal2-html-container {
    color: rgba(200, 200, 200, 0.6) !important;
    font-family: Space Mono;
    max-width: 400px;
    margin: 0px auto !important;
    margin-top: -5px !important;
}
.swal2-confirm {
    background: rgba(200, 200, 200, 0.1);
    border: 1px solid rgba(200, 200, 200, 0.3);
    order: 2;
    margin-top: 10px;
    border-radius: 7px;
}
.swal2-confirm:hover {
    background: rgba(200, 200, 200, 0.2);
}
.swal2-cancel {
    background: rgba(133, 47, 47, 0.2);
    border: 1px solid rgba(133, 47, 47, 0.4);
    order: 1;
    margin-top: 10px;
    border-radius: 7px;
}
.swal2-cancel:hover {
    background: rgba(133, 47, 47, 0.3);
}
.swal2-icon {
    margin: 40px auto;
    margin-bottom: 0px;
    color: #00fbbf !important;
    border-color: #00fbbf !important;
    font-size: 10px;
}
.swal2-success-line-long,
.swal2-success-line-tip {
    background: #00fbbf !important;
}

.swal2-success-circular-line-left,
.swal2-success-circular-line-right,
.swal2-success-fix {
    display: none;
}
.swal2-backdrop-show {
    background: rgba(0, 0, 0, 0.6) !important;
    backdrop-filter: blur(10px); /* Standard backdrop blur */
    -webkit-backdrop-filter: blur(10px); /* Safari support */
}
.swal2-input {
    gap: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 0.5rem;
    border-width: 1px;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #ffffff;
    backdrop-blur: blur(4px);
    background: #ffffff30;
}
.swal2-x-mark-line-left,
.swal2-x-mark-line-right {
    background-color: #00fbbf !important;
}

/*animations*/
.fly-in-from-bottom {
    opacity: 0;
    transform: translateY(20px);
    animation: flyIn 0.6s cubic-bezier(0.17, 0.73, 0.17, 1) forwards;
}
.fly-out-to-bottom {
    opacity: 0;
    transform: translateY(0px);
    animation: flyOut 0.3s cubic-bezier(0.17, 0.73, 0.17, 1) backwards;
}

@keyframes flyIn {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
@keyframes flyOut {
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(10px);
    }
}

.pop-in {
    animation: popIn 0.2s ease-in-out forwards;
    opacity: 0; /* Initially hidden */
}

@keyframes popIn {
    0% {
        opacity: 0;
        transform: scale(0.9); /* Start slightly smaller */
    }
    60% {
        opacity: 1;
        transform: scale(1.1); /* Overshoot slightly */
    }
    100% {
        opacity: 1;
        transform: scale(1); /* Return to normal size */
    }
}

.shake-in {
    animation: shake 0.3s ease-in-out;
}

@keyframes shake {
    0% {
        transform: translateX(0);
    }
    15% {
        transform: translateX(-3px);
    }
    30% {
        transform: translateX(3px);
    }
    45% {
        transform: translateX(-3px);
    }
    60% {
        transform: translateX(3px);
    }
    75% {
        transform: translateX(-3px);
    }
    90% {
        transform: translateX(3px);
    }
    100% {
        transform: translateX(0);
    }
}

.rotate-y-180 {
    transform: rotateY(180deg);
  }
